var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.modal_report",modifiers:{"modal_report":true}}],staticClass:"bg-dark p-4 rounded position-relative h-100 d-flex flex-column"},[_c('vs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"position-absolute font-weight-bold",staticStyle:{"display":"grid","place-items":"center","top":"-5px","right":"-5px","width":"2rem"},attrs:{"circle":"","danger":"","relief":"","title":"Cantidad de Reportes"}},[_vm._v(" "+_vm._s(_vm.cantidadResportes ?? 0)+" ")]),_c('h3',{staticClass:"text-white-50"},[_vm._v("Reportes")]),_vm._m(0),_c('b-modal',{attrs:{"size":"xl","id":"modal_report","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_vm._v(" Reportes "),_c('span',{staticClass:"text-secondary ml-2"},[_vm._v("|")]),_c('input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],ref:"passwordInput",class:{
            'password-input': true,
            'is-show': _vm.showPassword,
          },attrs:{"title":"Contraseña de Reportes","readonly":"","type":`${_vm.showPassword ? 'text' : 'password'}`},domProps:{"value":_vm.usuario?.contrasenaReportes}}),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:`far fa-${
            _vm.copyPasswordText ? 'circle-check' : 'clipboard'
          } mr-2 zoom text-white-50`,attrs:{"title":"Copiar al portapapeles"},on:{"click":function($event){return _vm.copyToClipboard(
              'top-left',
              'primary',
              `<i class='fas fa-clipboard'></i>`
            )}}}),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:`far fa-${
            _vm.showPassword ? 'eye-slash' : 'eye'
          } zoom text-white-50`,attrs:{"title":"Mostrar / Ocultar"},on:{"click":_vm.togglePasswordVisibility}})])]},proxy:true}])},[_c('slidesReport')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-12 col-lg d-flex flex-column justify-content-center align-items-center text-center"},[_c('div',{staticClass:"rounded-circle text-white d-flex justify-content-center align-items-center icon-reports",staticStyle:{"width":"100px","height":"100px","background":"radial-gradient(circle, #ff94a7, #d70027)","box-shadow":"0px -1px 33px -8px #d70027","-webkit-box-shadow":"0px -1px 33px -8px #d70027","-moz-box-shadow":"0px -1px 33px -8px #d70027"}},[_c('i',{staticClass:"fas fa-chart-simple",staticStyle:{"font-size":"2.5rem"}})]),_c('p',{staticClass:"text-white-50 mt-2",staticStyle:{"line-height":"1.5","font-size":"1rem"}},[_vm._v(" Haz click aquí para visualizar tus reportes. ")])])])
}]

export { render, staticRenderFns }