<template>
  <div
    class="bg-dark p-4 rounded position-relative h-100 d-flex flex-column"
    v-b-modal.modal_report
  >
    <!-- cantidad de reportes -->
    <vs-button
      class="position-absolute font-weight-bold"
      circle
      danger
      relief
      v-b-tooltip.hover
      title="Cantidad de Reportes"
      style="
        display: grid;
        place-items: center;
        top: -5px;
        right: -5px;
        width: 2rem;
      "
    >
      {{ cantidadResportes ?? 0 }}
    </vs-button>

    <h3 class="text-white-50">Reportes</h3>

    <div class="row h-100">
      <div
        class="col-12 col-lg d-flex flex-column justify-content-center align-items-center text-center"
      >
        <div
          class="rounded-circle text-white d-flex justify-content-center align-items-center icon-reports"
          style="
            width: 100px;
            height: 100px;
            background: radial-gradient(circle, #ff94a7, #d70027);
            box-shadow: 0px -1px 33px -8px #d70027;
            -webkit-box-shadow: 0px -1px 33px -8px #d70027;
            -moz-box-shadow: 0px -1px 33px -8px #d70027;
          "
        >
          <i class="fas fa-chart-simple" style="font-size: 2.5rem"></i>
        </div>
        <p style="line-height: 1.5; font-size: 1rem" class="text-white-50 mt-2">
          Haz click aquí para visualizar tus reportes.
        </p>
      </div>
    </div>
    <!-- modal powerBIreport -->
    <b-modal size="xl" id="modal_report" hide-footer>
      <template #modal-title>
        <div class="d-flex align-items-center">
          Reportes
          <span class="text-secondary ml-2">|</span>
          <!-- password input-->
          <input
            v-b-tooltip.hover
            title="Contraseña de Reportes"
            readonly
            ref="passwordInput"
            :class="{
              'password-input': true,
              'is-show': showPassword,
            }"
            :type="`${showPassword ? 'text' : 'password'}`"
            :value="usuario?.contrasenaReportes"
          />
          <!-- copiar al portapapeles -->
          <i
            v-b-tooltip.hover
            title="Copiar al portapapeles"
            :class="`far fa-${
              copyPasswordText ? 'circle-check' : 'clipboard'
            } mr-2 zoom text-white-50`"
            @click="
              copyToClipboard(
                'top-left',
                'primary',
                `<i class='fas fa-clipboard'></i>`
              )
            "
          >
          </i>
          <!-- mostrar / ocultar -->
          <i
            v-b-tooltip.hover
            title="Mostrar / Ocultar"
            :class="`far fa-${
              showPassword ? 'eye-slash' : 'eye'
            } zoom text-white-50`"
            @click="togglePasswordVisibility"
          ></i>
        </div>
      </template>
      <slidesReport />
    </b-modal>
  </div>
</template>
<script>
import slidesReport from "./slidesReport.vue";

export default {
  components: {
    slidesReport,
  },
  data() {
    return {
      usuario: {},
      listaResportes: [],
      showPassword: false,
      copyPasswordText: null,
      cantidadResportes: null,
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.GetByCompanyIdAndReportingLevel(
        this.usuario.empresa.id,
        this.usuario.nivelReporte
      );
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      // console.log("error", error);
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async copyToClipboard(position = null, color, icon) {
      try {
        const inputPassword = this.$refs.passwordInput.value;
        if (inputPassword) {
          await navigator.clipboard.writeText(inputPassword);

          this.copyPasswordText = inputPassword;

          setTimeout(() => {
            this.copyPasswordText = null;
          }, 6000);

          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon,
            color,
            position,
            title: "Contraseña copiada!!",
          });
        }
      } catch (err) {
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          icon: '<i class="fas fa-xmark"></i>',
          color: "danger",
          position,
          title: "No se pudo copiar al portapapeles!!",
          text: err,
        });
        // console.error("No se pudo copiar al portapapeles!!: ", err);
      }
    },
    async GetByCompanyIdAndReportingLevel(companyId, reportLevel) {
      try {
        const nivelesReportes = JSON.parse(reportLevel);

        if (Array.isArray(nivelesReportes)) {
          nivelesReportes.forEach(async (nivel) => {
            let res = await this.$store.dispatch("hl_get", {
              path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivel}`,
            });
            // console.log("reportes----------------->", res, nivelesReportes);
            if (res && res.url) {
              this.listaResportes.push({
                url: res.url,
                descripcion: res.description,
                nivel: res.reportingLevel,
              });
              this.cantidadResportes = this.listaResportes.length;
            }
          });
        } else {
          let res = await this.$store.dispatch("hl_get", {
            path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivelesReportes}`,
          });
          // console.log("reportes----------------->", res, nivelesReportes);
          if (res.url) {
            this.listaResportes.push({
              url: res.url,
              descripcion: res.description,
              nivel: res.reportingLevel,
            });
            this.cantidadResportes = this.listaResportes.length;
          }
        }
      } catch (error) {
        // console.log("err", error);
      }
    },
  },
};
</script>
<style scoped>
.icon-reports {
  animation: leftAndRight 2s ease-in-out infinite;
}
/* password input */
.password-input {
  background: transparent;
  width: 80px;
  max-width: 120px;
  overflow: hidden;
  text-align: center;
  letter-spacing: 2px;
  text-overflow: ellipsis;
  max-height: 20px;
  border: none;
  color: var(--text-progress);
  font-size: 2rem;
  cursor: default;
  transition: all 0.3s ease;

  &.is-show {
    font-size: 1.1rem;
  }
}

@keyframes leftAndRight {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
