<template>
  <div class="p-4 bg-dark rounded h-100 d-flex flex-column">
    <!-- icon -->
    <div
      v-b-tooltip.hover
      title="Ir a Medallas"
      class="position-absolute"
      style="top: 0 !important; right: 0 !important"
    >
      <vs-button flat icon success to="/helex/perfil/medallas">
        <i class="fas fa-paper-plane"></i>
      </vs-button>
    </div>
    <!-- title -->
    <h3 class="text-white-50 text-truncate mb-2">Medallas</h3>

    <div class="grid-medallas" v-if="listaMedallas.length">
      <div
        v-b-tooltip.hover
        title="Haz clic para más detalles de la medalla."
        style="position: relative !important"
        v-for="(medalla, index) in listaMedallas"
        :key="`${index}_medalla`"
      >
        <picture @click="showModalDetalles(medalla)">
          <img
            class="zoom"
            width="200px"
            :src="medalla[0].medallas.imagen"
            :alt="medalla[0].medallas.descripcion"
          />
          <vs-button
            class="font-weight-bold"
            circle
            relief
            style="right: 5px; position: absolute; top: 0; width: 2rem"
            >{{ medalla.length }}</vs-button
          >
        </picture>
      </div>

      <div
        v-b-tooltip.hover
        title="Sin medalla disponible.!"
        v-show="listaMedallas.length < medal"
        v-for="medal in 5"
        :key="medal"
      >
        <vs-button icon circle flat
          ><i
            class="fas fa-medal zoom text-white-50"
            style="font-size: 2rem"
          ></i
        ></vs-button>
      </div>
    </div>
    <div v-else class="grid-medallas">
      <div
        v-b-tooltip.hover
        title="Sin medalla disponible.!"
        v-for="medal in 5"
        :key="`${medal}_id`"
      >
        <vs-button icon circle flat
          ><i
            class="fas fa-medal zoom text-white-50"
            style="font-size: 2rem"
          ></i
        ></vs-button>
      </div>
    </div>
    <!-- modal detalles medallas -->
    <b-modal
      id="modal_medallas"
      title="Medallas"
      size="lg"
      centered
      hide-footer
    >
      <b-card class="w-100">
        <div class="row" style="gap: 1rem">
          <!-- medalla info -->
          <div class="col-12 bg-dark rounded position-relative">
            <!-- cantidad medalals -->
            <div>
              <vs-button
                danger
                relief
                v-b-tooltip.hover
                title="Cantidad de medallas"
                class="position-absolute font-weight-bold"
                style="
                  display: grid;
                  place-items: center;
                  top: -5px;
                  right: -5px;
                  width: 2rem;
                "
              >
                {{ dataModalMedallas.emisoresMedallas.length }}
              </vs-button>
            </div>
            <div class="row">
              <!-- imagen medalla -->
              <div
                class="col-12 d-flex align-items-center justify-content-center position-relative overflow-hidden"
                style="height: 300px"
              >
                <img
                  class="image-medalla position-absolute"
                  style="width: 300px"
                  :src="dataModalMedallas.imagenMedalla"
                  :alt="dataModalMedallas.descripcionMedalla"
                />
                <img
                  class="image-medalla2 position-absolute"
                  style="width: 300px"
                  :src="dataModalMedallas.imagenMedalla"
                  :alt="dataModalMedallas.descripcionMedalla"
                />
              </div>

              <div class="col-12 d-flex justify-content-center">
                <!-- descripcion -->
                <span class="h3 font-weight-bold">{{
                  dataModalMedallas.descripcionMedalla
                }}</span>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <h3 class="font-weight-bold">¿Quién ha enviado esta medalla?</h3>
            <p class="text-white-50 h4">
              A continuación se muestran los usuarios que te han enviado ésta
              medalla.
            </p>
          </div>
          <!-- lista de usuario -->
          <div class="col-12">
            <div class="row" style="gap: 0.5rem">
              <div
                v-for="(user, index) in dataModalMedallas.emisoresMedallas"
                :key="`${index}_user`"
                class="col-12 col-lg bg-dark rounded p-2 d-flex flex-column justify-content-between"
              >
                <vs-avatar
                  class="rounded mb-1"
                  size="30"
                  :badge="user.medallas.isRecent"
                  badge-position="top-right"
                >
                  <i
                    class="fas fa-user text-primary"
                    style="font-size: 1rem"
                  ></i>
                </vs-avatar>
                <div class="py-2">
                  <h3 class="font-weight-bold text-white-50">
                    {{ user.medallas.emisor }}
                  </h3>
                  <p
                    v-if="user.comentario"
                    class="text-white-50 text-truncate mt-2"
                    style="line-height: 1.5; font-size: 0.8rem"
                  >
                    {{ user.comentario }}
                  </p>
                </div>
                <span>
                  <i class="fas fa-calendar mr-1"></i>
                  <span>{{ user.fechaObtencion | formatDate }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import moment_timezone from "moment-timezone";

export default {
  components: {},
  data() {
    return {
      usuario: {},
      dataModalMedallas: {
        imagenMedalla: "",
        descripcionMedalla: "",
        cantidadMedallas: 1,
        emisoresMedallas: [],
        currentPosition: 0,
        imageWidth: 0,
      },
      listaMedallas: [],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData(this.usuario.id);
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      // console.log("error", error);
    }
  },
  methods: {
    showModalDetalles(dataMedalla) {
      // console.log("dataMedalla", dataMedalla);
      this.dataModalMedallas.imagenMedalla = dataMedalla[0].medallas.imagen;
      this.dataModalMedallas.descripcionMedalla =
        dataMedalla[0].medallas.descripcion;
      this.dataModalMedallas.emisoresMedallas = dataMedalla;
      this.$bvModal.show("modal_medallas");
    },
    isRecent(fecha) {
      // Obtener la fecha actual en el formato de moment_timezone
      const currentDate = moment_timezone();

      // Convertir la fecha de emisión a un objeto moment_timezone
      const emissionDate = moment_timezone.tz(fecha, "America/Bogota");

      // Calcular la diferencia en días
      const daysDifference = currentDate.diff(emissionDate, "days");

      // Devolver true si la diferencia es menor a 3 días, false en caso contrario
      return daysDifference < 3;
    },
    async getData(usuarioId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "MedallasPersona/ListByUsuario/" + usuarioId,
        });
        // console.log("list medallas... ", res);
        if (res.length > 0) {
          for (let index in res) {
            res[index].medallas.emisor = await this.getPersonaByID(
              res[index].emisorId
            );
            res[index].medallas.isRecent = this.isRecent(
              res[index].fechaObtencion
            );
          }

          let medallasAgrupadas = {};

          res.forEach((medalla) => {
            const medallaID = medalla.medallas.id;
            if (
              Object.prototype.hasOwnProperty.call(medallasAgrupadas, medallaID)
            ) {
              medallasAgrupadas[medallaID].push(medalla);
            } else {
              medallasAgrupadas[medallaID] = [medalla];
            }
          });

          const resul = Object.values(medallasAgrupadas);

          // console.log("resultado", resul);

          // Imprimir el resultado
          // resul.forEach((arrayObjetos) => {
          //   console.log("medallasAgrupadas", arrayObjetos);
          // });

          this.listaMedallas = resul;
        } else {
          this.listaMedallas = [];
        }
      } catch (error) {
        this.listaMedallas = [];
        // console.log("err", error);
      }
    },
    async getPersonaByID(emisorId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/GetPersona/" + emisorId,
        });
        // console.log("emisor... ", `${res.nombres} ${res.apellidos}`);
        // localStorage.setItem("setPersonaAct", JSON.stringify(res));
        return `${res.nombres} ${res.apellidos}`;
      } catch (error) {
        // console.log("err", error);
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment_timezone.tz(date, "America/Bogota").format("MMM D, YYYY");
    },
  },
};
</script>
<style scoped>
.grid-medallas {
  margin-top: 2rem;
  display: grid;
  place-items: center;
  row-gap: 3rem;
  grid-template-columns: repeat(auto-fill, minmax(60px, 100px));
}

.image-medalla,
.image-medalla2 {
  animation: leftAndRight 2s ease-in-out infinite;
}
.image-medalla {
  -webkit-box-reflect: left -120px -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(transparent),
      to(rgba(255, 255, 255, 0.3))
    );
}

.image-medalla2 {
  -webkit-box-reflect: right -100px -webkit-gradient(
      linear,
      right bottom,
      right top,
      from(transparent),
      to(rgba(255, 255, 255, 0.3))
    );
}
@keyframes leftAndRight {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
