<template>
  <div class="bg-dark px-3 py-4 rounded position-relative">
    <!-- progreso hoja de vida -->
    <div
      class="position-absolute text-center"
      style="top: 0 !important; right: 0 !important"
    >
      <vs-button
        class="w-100 my-0 bg-transparent"
        flat
        dark
        to="/helex/hoja_vida"
      >
        <progress-bubble
          v-b-tooltip.hover
          :title="`Progreso de Hoja de Vida - ${progresoHojaDeVida.toFixed(
            0
          )}%`"
          :size="70"
          :percent="Number(progresoHojaDeVida.toFixed(0))"
        />
      </vs-button>
    </div>
    <div class="row">
      <!-- agregar foto de perfil -->
      <div
        class="col-12 col-lg-2 d-flex justify-content-center"
        style="position: relative; align-items: center !important"
      >
        <div
          v-b-tooltip.hover
          title="Haz click para cambiar tu avatar"
          class="circle-container"
        >
          <router-link
            :to="{ name: 'MiAvatar' }"
            for="file-upload"
            class="circle"
          >
            <RenderResultAvatar />
          </router-link>
        </div>
      </div>
      <!-- info profile -->
      <div class="col-12 col-lg d-flex flex-column justify-content-between">
        <!-- nombre usuario y rol -->
        <div>
          <h2 class="text-center text-lg-left py-2" style="font-weight: 700">
            {{ usuario.nombres }} {{ usuario.apellidos }}
          </h2>
          <div class="d-flex flex-wrap" style="gap: 2rem">
            <span v-if="estadisticasProfile?.cargo" class="text-white-50">
              {{ estadisticasProfile?.cargo }}
            </span>
            <div
              v-b-tooltip.hover
              :title="`${nivelCompetenciaComplejidad?.descripcion}`"
              v-if="nivelCompetenciaComplejidad?.id"
            >
              <span
                v-if="nivelCompetenciaComplejidad?.id"
                class="text-white-50"
              >
                Nivel Competencia: {{ nivelCompetenciaComplejidad?.id }}
              </span>
            </div>
          </div>
        </div>
        <!-- info details  -->
        <div
          class="d-flex justify-content-lg-between flex-wrap"
          style="gap: 1rem"
        >
          <!-- mi pulso -->

          <div
            v-b-tooltip.hover
            :title="`${usuario.nombres} ${usuario.apellidos}`"
            class="d-flex align-items-center"
          >
            <vs-button
              icon
              flat
              :danger="estadisticasProfile.pulsoPersona <= 50"
              :success="estadisticasProfile.pulsoPersona >= 80"
              :warn="
                estadisticasProfile.pulsoPersona >= 60 &&
                estadisticasProfile.pulsoPersona < 80
              "
            >
              <i
                :class="`fas fa-arrow-trend-${
                  estadisticasProfile.pulsoPersona < 50 ? 'down' : 'up'
                }`"
                style="font-size: 1.5rem"
              ></i>
            </vs-button>
            <div>
              <h3 style="font-size: 1.5rem; font-weight: 800">
                {{ estadisticasProfile.pulsoPersona || 0 }}%
              </h3>
              <span class="text-white-50">Mi Pulso</span>
            </div>
          </div>

          <!-- pulso empresa -->

          <div
            v-b-tooltip.hover
            :title="`${estadisticasProfile.nombre_empresa || 'Sin Asignar'}`"
            class="d-flex align-items-center"
          >
            <vs-button
              icon
              flat
              :danger="estadisticasProfile.pulsoEmpresa <= 50"
              :success="estadisticasProfile.pulsoEmpresa >= 80"
              :warn="
                estadisticasProfile.pulsoEmpresa >= 60 &&
                estadisticasProfile.pulsoEmpresa < 80
              "
            >
              <i
                :class="`fas fa-arrow-trend-${
                  estadisticasProfile.pulsoEmpresa < 50 ? 'down' : 'up'
                }`"
                style="font-size: 1.5rem"
              ></i>
            </vs-button>
            <div>
              <h3 style="font-size: 1.5rem; font-weight: 800">
                {{ estadisticasProfile.pulsoEmpresa || 0 }}%
              </h3>
              <span class="text-white-50">Pulso Empresa</span>
            </div>
          </div>

          <!-- pulso departamento -->
          <div
            v-b-tooltip.hover
            :title="`${estadisticasProfile.departamento || 'Sin Asignar'}`"
            class="d-flex align-items-center"
          >
            <vs-button
              icon
              flat
              :danger="estadisticasProfile.pulsoDepartamento <= 50"
              :success="estadisticasProfile.pulsoDepartamento >= 80"
              :warn="
                estadisticasProfile.pulsoDepartamento >= 60 &&
                estadisticasProfile.pulsoDepartamento < 80
              "
            >
              <i
                :class="`fas fa-arrow-trend-${
                  estadisticasProfile.pulsoDepartamento < 50 ? 'down' : 'up'
                }`"
                style="font-size: 1.5rem"
              ></i>
            </vs-button>
            <div>
              <h3 style="font-size: 1.5rem; font-weight: 800">
                {{ estadisticasProfile.pulsoDepartamento || 0 }}%
              </h3>
              <span class="text-white-50">Pulso Departamento</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RenderResultAvatar from "../MiAvatar/RenderResultAvatar.vue";
import progressBubble from "@/components/progress/progressBubble.vue";

// const Swal = require("sweetalert2");

export default {
  components: {
    RenderResultAvatar,
    progressBubble,
  },
  data() {
    return {
      usuario: {},
      progresoHojaDeVida: 0,
      nivelCompetenciaComplejidad: {
        id: null,
        descripcion: null,
      },
      perfilCargo: {},
      file: null,
      filename: null,
      estadisticasProfile: {
        empresaId: null,
        personaId: null,
        departamentoId: null,
        cargoId: null,
        nombre_empresa: null,
        departamento: null,
        nombreEvaluado: null,
        cargo: null,
        pulsoEmpresa: 0,
        pulsoDepartamento: 0,
        pulsoPersona: 0,
      },
      personaUbicacion: {
        cargo: {
          id: null,
          nombre: "Cargo",
        },
      },
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getProgresoHojaDeVida(this.usuario.id);
      await this.getPersonaUbicacion(this.usuario.id);
      let cargoId = this.personaUbicacion?.cargo.id;
      await this.getPerfilCargoByCargoId(cargoId);
      await this.getNivelCompetenciaComplejidad();
      const { id, empresa } = this.usuario;
      await this.getEstadisticasProfile(id, empresa.id);
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      // console.log("error", error);
    }
  },
  methods: {
    async getProgresoHojaDeVida(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `HojaDeVidaProgreso/${personaId}`,
        });
        console.log("progresoHojaDeVida------------>", res);
        if (res !== null) {
          this.progresoHojaDeVida = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getNivelCompetenciaComplejidad() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `NivelCompetenciaComplejidad/ListNivelCompetenciaComplejidad`,
        });
        // console.log("nivel competencia complejidad------------>", res);
        if (res.length) {
          this.nivelCompetenciaComplejidad = res.filter(
            (ncc) => ncc.id === this.perfilCargo.nivelCompetenciaComplejidadId
          )[0];

          // console.log(this.nivelCompetenciaComplejidad);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getPerfilCargoByCargoId(cargoId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `PerfilCargo/consultarPerfilCargoAll/${cargoId}`,
        });
        console.log("perfil cargo------------>", res.perfilCargo);
        if (res.perfilCargo.id) {
          this.perfilCargo = res.perfilCargo;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getEstadisticasProfile(personaId, empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `EstadisticasDashboardProfile/${personaId}/${empresaId}`,
        });
        console.log("estadisticas profile------------>", res);
        if (res.success && res.data) {
          this.estadisticasProfile = res.data;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getPersonaUbicacion(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + personaId,
        });
        console.log("persona ubicacion...", res);
        if (res?.cargo) {
          this.personaUbicacion = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getPersonaByIDAfterUploadImagenPerfil(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/GetPersona/" + personaId,
        });
        // console.log("PersonaAfterUploadImagenPerfil... ", res);

        localStorage.setItem("setPersonaAct", JSON.stringify(res));

        this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      } catch (error) {
        // console.log("err", error);
      }
    },
    uploadImage() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      // Realiza las acciones necesarias con el archivo de imagen
      // console.log("Archivo seleccionado:", file);

      this.uploadImagenPerfilUrl(file);
    },
    async renameFile(nameFile) {
      this.realName = nameFile;
      let collectionBad = "á é í ó ú Á É Í Ó Ú ñ Ñ";
      let collectionGood = "a e i o u A E I O U n N";
      let collectionBadA = collectionBad.split(" ");
      let collectionGoodA = collectionGood.split(" ");
      let count = 0;
      for await (let it of collectionBadA) {
        let pos = nameFile.indexOf(it);
        if (pos > 0) {
          nameFile = nameFile.replaceAll(it, collectionGoodA[count]);
        }
        pos = 0;
        count++;
      }
      return nameFile;
    },
    async uploadImagenPerfilUrl(file) {
      try {
        this.$isLoading(true);
        this.file = file; //event.target.files[0];
        this.filename = await this.renameFile(this.file.name); // Math.floor(Math.random() * 9999999999999 + 1);
        // console.log("aaaaaaaaaaaaaaa", this.file, this.filename);
        if (this.filename != null) {
          let url = ".|site1|users|photoPerfil|" + this.usuario.id;

          let uploadImg = await this.$store.dispatch("onFileCustom", {
            filename: this.filename,
            file: this.file,
            url: url,
          });

          if (uploadImg == true) {
            // console.log("archivo subido...", uploadImg);

            let data = {
              personaId: this.usuario.id,
              url:
                "https://www.helexiumfiles.com/users/photoPerfil/" +
                this.usuario.id +
                "/" +
                this.filename,
            };

            // console.log(data);

            try {
              await this.$store.dispatch("hl_post", {
                path: "Persona/UploadImagenPerfilUrl",
                data,
              });

              this.$vs.notification({
                // duration: "none",
                progress: "auto",
                color: "primary",
                icon: "<i class='fas fa-circle-check'></i>",
                position: "top-center",
                title: "Foto de perfil actualizada!",
              });

              this.getPersonaByIDAfterUploadImagenPerfil(data.personaId);
              // Swal.fire({
              //   title: "Listo!",
              //   text: "Se ha guardado la foto.",
              //   icon: "success",
              //   showCancelButton: false,
              //   confirmButtonColor: "#3085d6",
              //   cancelButtonColor: "#d33",
              //   confirmButtonText: "OK",
              //   cancelButtonText: "Cancelar",
              // }).then(async (result) => {
              //   if (result.isConfirmed) {
              //     this.getPersonaByIDAfterUploadImagenPerfil(data.personaId);
              //   }
              // });
            } catch (error) {
              // console.error(error);
            }
          }
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        // Swal.fire(
        //   "Error!",
        //   "Ha ocurrido un error al guardar la foto.",
        //   "error"
        // );
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          color: "danger",
          icon: "<i class='fas fa-xmark'></i>",
          position: "top-center",
          title: "Ha ocurrido un error al subir la foto.",
        });
        // console.log("errror upload", error);
      }
    },
  },
};
</script>
<style scoped>
/* agregar imagen perfil */
.circle-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.circle {
  position: relative;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  border: 2px solid rgb(242, 19, 93);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  transition: all 1s ease;
}

.circle input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.circle:hover {
  /* glassEffect */
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-color: #186bdd;
}

.circle .icon {
  font-size: 2rem;
  transition: color 0.3s ease-in-out;
}

.circle:hover .icon {
  color: #186bdd;
}

.circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* imagenperfil */
.circle-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.circle-image {
  width: 50%;
  max-width: 300px;
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
