var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-dark px-3 py-4 rounded position-relative"},[_c('div',{staticClass:"position-absolute text-center",staticStyle:{"top":"0 !important","right":"0 !important"}},[_c('vs-button',{staticClass:"w-100 my-0 bg-transparent",attrs:{"flat":"","dark":"","to":"/helex/hoja_vida"}},[_c('progress-bubble',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":`Progreso de Hoja de Vida - ${_vm.progresoHojaDeVida.toFixed(
          0
        )}%`,"size":70,"percent":Number(_vm.progresoHojaDeVida.toFixed(0))}})],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-2 d-flex justify-content-center",staticStyle:{"position":"relative","align-items":"center !important"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"circle-container",attrs:{"title":"Haz click para cambiar tu avatar"}},[_c('router-link',{staticClass:"circle",attrs:{"to":{ name: 'MiAvatar' },"for":"file-upload"}},[_c('RenderResultAvatar')],1)],1)]),_c('div',{staticClass:"col-12 col-lg d-flex flex-column justify-content-between"},[_c('div',[_c('h2',{staticClass:"text-center text-lg-left py-2",staticStyle:{"font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.usuario.nombres)+" "+_vm._s(_vm.usuario.apellidos)+" ")]),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"2rem"}},[(_vm.estadisticasProfile?.cargo)?_c('span',{staticClass:"text-white-50"},[_vm._v(" "+_vm._s(_vm.estadisticasProfile?.cargo)+" ")]):_vm._e(),(_vm.nivelCompetenciaComplejidad?.id)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":`${_vm.nivelCompetenciaComplejidad?.descripcion}`}},[(_vm.nivelCompetenciaComplejidad?.id)?_c('span',{staticClass:"text-white-50"},[_vm._v(" Nivel Competencia: "+_vm._s(_vm.nivelCompetenciaComplejidad?.id)+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"d-flex justify-content-lg-between flex-wrap",staticStyle:{"gap":"1rem"}},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-flex align-items-center",attrs:{"title":`${_vm.usuario.nombres} ${_vm.usuario.apellidos}`}},[_c('vs-button',{attrs:{"icon":"","flat":"","danger":_vm.estadisticasProfile.pulsoPersona <= 50,"success":_vm.estadisticasProfile.pulsoPersona >= 80,"warn":_vm.estadisticasProfile.pulsoPersona >= 60 &&
              _vm.estadisticasProfile.pulsoPersona < 80}},[_c('i',{class:`fas fa-arrow-trend-${
                _vm.estadisticasProfile.pulsoPersona < 50 ? 'down' : 'up'
              }`,staticStyle:{"font-size":"1.5rem"}})]),_c('div',[_c('h3',{staticStyle:{"font-size":"1.5rem","font-weight":"800"}},[_vm._v(" "+_vm._s(_vm.estadisticasProfile.pulsoPersona || 0)+"% ")]),_c('span',{staticClass:"text-white-50"},[_vm._v("Mi Pulso")])])],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-flex align-items-center",attrs:{"title":`${_vm.estadisticasProfile.nombre_empresa || 'Sin Asignar'}`}},[_c('vs-button',{attrs:{"icon":"","flat":"","danger":_vm.estadisticasProfile.pulsoEmpresa <= 50,"success":_vm.estadisticasProfile.pulsoEmpresa >= 80,"warn":_vm.estadisticasProfile.pulsoEmpresa >= 60 &&
              _vm.estadisticasProfile.pulsoEmpresa < 80}},[_c('i',{class:`fas fa-arrow-trend-${
                _vm.estadisticasProfile.pulsoEmpresa < 50 ? 'down' : 'up'
              }`,staticStyle:{"font-size":"1.5rem"}})]),_c('div',[_c('h3',{staticStyle:{"font-size":"1.5rem","font-weight":"800"}},[_vm._v(" "+_vm._s(_vm.estadisticasProfile.pulsoEmpresa || 0)+"% ")]),_c('span',{staticClass:"text-white-50"},[_vm._v("Pulso Empresa")])])],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"d-flex align-items-center",attrs:{"title":`${_vm.estadisticasProfile.departamento || 'Sin Asignar'}`}},[_c('vs-button',{attrs:{"icon":"","flat":"","danger":_vm.estadisticasProfile.pulsoDepartamento <= 50,"success":_vm.estadisticasProfile.pulsoDepartamento >= 80,"warn":_vm.estadisticasProfile.pulsoDepartamento >= 60 &&
              _vm.estadisticasProfile.pulsoDepartamento < 80}},[_c('i',{class:`fas fa-arrow-trend-${
                _vm.estadisticasProfile.pulsoDepartamento < 50 ? 'down' : 'up'
              }`,staticStyle:{"font-size":"1.5rem"}})]),_c('div',[_c('h3',{staticStyle:{"font-size":"1.5rem","font-weight":"800"}},[_vm._v(" "+_vm._s(_vm.estadisticasProfile.pulsoDepartamento || 0)+"% ")]),_c('span',{staticClass:"text-white-50"},[_vm._v("Pulso Departamento")])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }