var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',{staticClass:"w-100 p-2"},[(_vm.listaReportes.length)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg bg-dark p-2 rounded reporte",staticStyle:{"height":"500px"}},[_c('div',{staticClass:"vista-previa d-flex justify-content-center align-items-center rounded"},[(_vm.listaReportes.length)?_c('modalIframe',{attrs:{"url":_vm.selectedReport?.url ?? '',"likeElement":true}}):_c('helexium-loader',{staticClass:"loader"})],1)]),_c('div',{staticClass:"col-12 col-lg-4"},[_c('div',{ref:"reportListContainer",staticClass:"row list-reports"},_vm._l((_vm.listaReportes),function(reporte,index){return _c('div',{key:index,staticClass:"col-12",on:{"click":function($event){return _vm.clickHandle(reporte, index)}}},[_c('div',{staticClass:"report-container",style:({
              transition: 'box-shadow 0.3s ease',
              boxShadow:
                index === _vm.selectedReport?.index
                  ? '0 0 0 3px #186bdd'
                  : 'none',
              cursor: 'pointer',
            })},[_c('div',{class:{
                'level-tag': true,
                active: index === _vm.selectedReport?.index || index === '',
              }},[_vm._v(" Nivel "+_vm._s(reporte.nivel)+" ")]),_c('div',{class:{
                'report-title': true,
                active: index === _vm.selectedReport?.index || index === '',
              }},[_c('i',{staticClass:"fas fa-chart-simple",staticStyle:{"font-size":"2rem"}}),_c('p',{staticClass:"custom-descripcion"},[_vm._v(" "+_vm._s(reporte.descripcion)+" ")])])])])}),0)])]):_c('div',{staticClass:"d-flex justify-content-center align-items-center text-center",staticStyle:{"height":"500px"}},[_c('h3',[_c('i',{staticClass:"far fa-face-sad-tear",staticStyle:{"font-size":"2rem"}}),_c('br'),_vm._v(" Sin reportes disponibles! ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }