<template>
  <card class="w-100 p-2">
    <div v-if="listaReportes.length" class="row">
      <!-- reporte -->
      <div
        class="col-12 col-lg bg-dark p-2 rounded reporte"
        style="height: 500px"
      >
        <div
          class="vista-previa d-flex justify-content-center align-items-center rounded"
        >
          <modalIframe
            v-if="listaReportes.length"
            :url="selectedReport?.url ?? ''"
            :likeElement="true"
          ></modalIframe>

          <helexium-loader class="loader" v-else />
        </div>
      </div>
      <!-- content list reports -->
      <div class="col-12 col-lg-4">
        <div class="row list-reports" ref="reportListContainer">
          <div
            v-for="(reporte, index) in listaReportes"
            :key="index"
            class="col-12"
            @click="clickHandle(reporte, index)"
          >
            <div
              class="report-container"
              :style="{
                transition: 'box-shadow 0.3s ease',
                boxShadow:
                  index === selectedReport?.index
                    ? '0 0 0 3px #186bdd'
                    : 'none',
                cursor: 'pointer',
              }"
            >
              <!-- level report -->
              <div
                :class="{
                  'level-tag': true,
                  active: index === selectedReport?.index || index === '',
                }"
              >
                Nivel {{ reporte.nivel }}
              </div>
              <div
                :class="{
                  'report-title': true,
                  active: index === selectedReport?.index || index === '',
                }"
              >
                <i class="fas fa-chart-simple" style="font-size: 2rem"></i>
                <p class="custom-descripcion">
                  {{ reporte.descripcion }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- sin reportes -->
    <div
      v-else
      class="d-flex justify-content-center align-items-center text-center"
      style="height: 500px"
    >
      <h3>
        <i class="far fa-face-sad-tear" style="font-size: 2rem"></i>
        <br />
        Sin reportes disponibles!
      </h3>
    </div>
  </card>
</template>

<script>
import modalIframe from "@/components/helexium/modalIframe.vue";
import HelexiumLoader from "@/components/helexium/HelexiumLoader.vue";
export default {
  components: {
    modalIframe,
    HelexiumLoader,
  },
  data() {
    return {
      usuario: {},
      selectedReport: null,
      listaReportes: [],
    };
  },
  mounted() {
    this.initializedData();
  },
  methods: {
    async initializedData() {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.GetByCompanyIdAndReportingLevel(
        this.usuario.empresa.id,
        this.usuario.nivelReporte
      );
      this.$isLoading(false);
    },
    clickHandle(reporte, index) {
      this.selectedReport = reporte;
      this.selectedReport.index = index;
      // Obtener el contenedor de la lista de clases
      const container = this.$refs.reportListContainer;

      // Obtener el elemento de la lista correspondiente al reporte seleccionado
      const element = container.children[index];

      // Calcular la posición del centro del contenedor
      const containerCenter = container.clientHeight / 2;

      // Calcular la posición del elemento seleccionado
      const elementCenter = element.offsetTop + element.clientHeight / 2;

      // Ajustar el scroll para que la clase seleccionada quede centrada verticalmente
      container.scrollTop = elementCenter - containerCenter;
    },
    async GetByCompanyIdAndReportingLevel(companyId, reportLevel) {
      try {
        const nivelesReportes = JSON.parse(reportLevel);

        if (Array.isArray(nivelesReportes)) {
          nivelesReportes.forEach(async (nivel) => {
            let res = await this.$store.dispatch("hl_get", {
              path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivel}`,
            });
            // console.log("reportes----------------->", res, nivelesReportes);
            if (res && res.url) {
              this.listaReportes.push({
                url: res.url,
                descripcion: res.description,
                nivel: res.reportingLevel,
              });
              this.listaReportes.sort((a, b) => a.nivel - b.nivel);
              this.selectedReport = { ...this.listaReportes[0], index: 0 };
            }
          });
        } else {
          let res = await this.$store.dispatch("hl_get", {
            path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivelesReportes}`,
          });
          // console.log("reportes----------------->", res, nivelesReportes);
          if (res.url) {
            this.listaReportes = [
              {
                url: res.url,
                descripcion: res.description,
                nivel: res.reportingLevel,
              },
            ];
            this.selectedReport = { ...this.listaReportes[0], index: 0 };
          }
        }
      } catch (error) {
        // console.log("err", error);
      }
    },
  },
};
</script>

<style scoped>
/* vista previa */
.vista-previa {
  position: relative;
  width: 100%;
  height: 100%;
}

/* list reports */
.list-reports {
  max-height: 500px;
  overflow-y: scroll;
  gap: 0.5rem;
  scroll-behavior: smooth;
}

/* report info */
.report-container {
  position: relative;
  width: 100%;
  border-radius: 0.2rem;
  margin: 0.2rem 0;
  background-color: rgba(236, 236, 236, 1);

  .dark & {
    background-color: rgba(19, 20, 22, 1);
  }

  & .report-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    height: 200px;
    font-size: 0.9rem;

    &.active p,
    &.active i {
      color: #1a5cff !important;
    }
  }
}

/* level tag */
.level-tag {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 0 0 0.5rem 0;
  background-color: rgba(1, 1, 1, 0.8);

  .dark & {
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
  }
  &.active {
    background-color: #186bdd;
    color: #fff;
  }
}

.custom-descripcion {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (max-width: 767px) {
  .reporte {
    height: 300px !important;
    margin-bottom: 1rem;
  }
}
</style>
