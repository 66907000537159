<template>
  <div
    class="bg-dark p-4 rounded position-relative h-100 d-flex flex-column justify-content-between"
  >
    <!-- icon -->
    <div
      v-b-tooltip.hover
      :title="options.toTitle ?? 'Ir al Dashboard (ejemplo)'"
      class="position-absolute"
      style="top: 0 !important; right: 0 !important"
    >
      <vs-button flat icon success :to="options.to ?? '/dashboard'">
        <i :class="`fas fa-${options.icon ?? 'circle-up'}`"></i>
      </vs-button>
    </div>
    <!-- text info -->
    <div class="d-flex flex-column">
      <h3 class="text-white-50 text-truncate">
        {{ options.title ?? "Info progress" }}
      </h3>
      <span class="font-weight-bold h4">{{ options.info ?? "0 min" }}</span>
    </div>
    <div class="d-flex justify-content-center align-items-center h-100 p-1">
      <ProgressChart
        :total="options.total ?? 0"
        :confirmed="options.confirmed ?? 0"
      />
    </div>
  </div>
</template>
<script>
import ProgressChart from "@/components/charts/ProgressChart.vue";
export default {
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          // title: "",
          // info: "",
          // icon: "",
          // to: "",
          // toTitle: "",
          // total: 0,
          // confirmed: 0,
        };
      },
    },
  },
  components: { ProgressChart },
  data() {
    return {
      usuario: {},
      listaResportes: [],
      cantidadResportes: null,
    };
  },
  mounted() {
    this.initializedData();
  },
  methods: {
    async initializedData() {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      const { nivelReporte, empresa } = this.usuario;
      await this.GetByCompanyIdAndReportingLevel(empresa.id, nivelReporte);
      this.$isLoading(false);
    },
    async GetByCompanyIdAndReportingLevel(companyId, reportLevel) {
      try {
        const nivelesReportes = JSON.parse(reportLevel);

        if (Array.isArray(nivelesReportes)) {
          nivelesReportes.forEach(async (nivel) => {
            let res = await this.$store.dispatch("hl_get", {
              path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivel}`,
            });
            // console.log("reportes----------------->", res, nivelesReportes);
            if (res && res.url) {
              this.listaResportes.push({
                url: res.url,
                descripcion: res.description,
                nivel: res.reportingLevel,
              });
              this.cantidadResportes = this.listaResportes.length;
            }
          });
        } else {
          let res = await this.$store.dispatch("hl_get", {
            path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivelesReportes}`,
          });
          // console.log("reportes----------------->", res, nivelesReportes);
          if (res.url) {
            this.listaResportes.push({
              url: res.url,
              descripcion: res.description,
              nivel: res.reportingLevel,
            });
            this.cantidadResportes = this.listaResportes.length;
          }
        }
      } catch (error) {
        // console.log("err", error);
      }
    },
  },
};
</script>
<style scoped></style>
